import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { graphql } from "gatsby"
import { LandingTemplate } from "../../components/Templates/LandingTemplate"

const Landing = ({ data }) => {
  const { content, seo, featuredImage, avFlexibleContent } = data.page

  return (
    <Layout variant="landing" pageContext={data?.page}>
      <SEO
        title={seo.title}
        image={featuredImage?.node?.sourceUrl}
        description={seo.metaDesc}
      />
      <LandingTemplate
        pages={data.allWpPage.nodes}
        content={content}
        flexibleContent={
          avFlexibleContent?.avFlexibleContentData?.flexibleContent
        }
      />
    </Layout>
  )
}

export default Landing

export const pageQuery = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      excerpt
      content
      uri
      avFlexibleContent {
        avFlexibleContentData {
          flexibleContent
        }
      }

      ancestors {
        nodes {
          ... on WpPage {
            depth
            title
            uri
          }
        }
      }
      seo {
        title
        metaDesc
      }
      headerSettings {
        customPageTitle
      }
      featuredImage {
        node {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
    }

    allWpPage(
      filter: { wpParent: { node: { id: { eq: $id } } } }
      sort: { fields: menuOrder, order: ASC }
    ) {
      nodes {
        uri
        title
        menuOrder
        excerpt

        featuredImage {
          node {
            sourceUrl
            srcSet
            mediaDetails {
              height
              width
            }
          }
        }
      }
    }
  }
`
